import AdminNav from "components/admin/AdminNav";
import CrispChat from "components/general/CrispChat";
import FullScreenLoader from "components/general/FullScreenLoader";
import DashboardLayout from "components/layout/DashboardLayout";
import DefaultLayout from "components/layout/DefaultLayout";
import { AthenaStatus, Cloud, SetupStage } from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import useRequireAuth from "hooks/use-require-auth";
import BusinessUnitDetailsPage from "pages/admin/BusinessUnitDetailsPage";
import BusinessUnitPage from "pages/admin/BusinessUnitPage";
import ClientManagementPage from "pages/admin/ClientManagementPage";
import CompanyDetailsPage from "pages/admin/CompanyDetailsPage";
import UsersPage from "pages/admin/UsersPage";
import AWSSetupResults from "pages/AWSSetupResults";
import ChooseRegionsPage from "pages/ChooseRegions";
import ConnectPage from "pages/ConnectPage";
import ConnectAwsPage from "pages/ConnectAwsPage";
import ConnectGcpPage from "pages/ConnectGcpPage";
import ConnectAthenaPage from "pages/ConnectAthenaPage";
import DashboardPage from "pages/DashboardPage";
import DiagnosticDashboardPage from "pages/DiagnosticDashboardPage";
import OpportunitiesPage from "pages/OpportunitiesPage";
import OpportunityPage from "pages/OpportunityPage";
import SigninupPage from "pages/SigninupPage";
import VerifyCodePage from "pages/VerifyCodePage";
import VerifySetupGCPPage from "pages/VerifySetupGCPPage";
import VerifySetupAWSPage from "pages/VerifySetupAWSPage";
import queryString from "query-string";

import * as React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import useAnalytics from "hooks/use-analytics";
import useLocalStorageState from "use-local-storage-state";
import DiagnosticsAthenaPage from "pages/DiagnosticsAthenaPage";

import useRequireAthenaAuth from "hooks/use-require-athena-auth";
import AthenaResultsProcessing from "pages/AthenaResultsProcessing";

import { adminPaths, authPaths } from "./routes";

interface AuthRoutesProps {
  hasUser: boolean;
}

const AthenaRoutes = ({ hasUser }: AuthRoutesProps) => {
  useRequireAthenaAuth();
  return (
    <DefaultLayout>
      <Switch>
        <Route
          path={authPaths.SIGNIN}
          exact
          render={() => <SigninupPage variant="signin" />}
        />
        <Route
          path={authPaths.SIGNUP}
          exact
          render={() => <SigninupPage variant="signup" />}
        />
        <Route path={authPaths.VERIFY_CODE} component={VerifyCodePage} />
        <Route exact path={authPaths.CONNECT} component={ConnectAthenaPage} />
        <Route
          path={authPaths.AWS_RESULTS_PROCESSING}
          component={AthenaResultsProcessing}
        />
        <Route
          path={authPaths.RESULTS_READY}
          component={DiagnosticsAthenaPage}
        />
        {hasUser ? <CrispChat /> : null}
      </Switch>
    </DefaultLayout>
  );
};
const CORoutes = ({ hasUser }: AuthRoutesProps) => {
  useRequireAuth();
  useAnalytics();
  return (
    <DefaultLayout>
      <Switch>
        <Route
          path={authPaths.SIGNIN}
          exact
          render={() => <SigninupPage variant="signin" />}
        />
        <Route
          path={authPaths.SIGNUP}
          exact
          render={() => <SigninupPage variant="signup" />}
        />
        <Route path={authPaths.VERIFY_CODE} component={VerifyCodePage} />
        <Route exact path={authPaths.CONNECT} component={ConnectPage} />
        <Route
          path={`${authPaths.CONNECT}/${Cloud.Aws}`}
          component={ConnectAwsPage}
        />
        <Route
          path={`${authPaths.CONNECT}/${Cloud.Gcp}`}
          component={ConnectGcpPage}
        />

        <Route
          path={`${authPaths.VERIFY_SETUP}/${Cloud.Aws}`}
          component={VerifySetupAWSPage}
        />
        <Route
          path={`${authPaths.VERIFY_SETUP}/${Cloud.Gcp}`}
          component={VerifySetupGCPPage}
        />

        <Route
          path={authPaths.AWS_RESULTS_PROCESSING}
          component={AWSSetupResults}
        />
        <Route path={authPaths.CHOOSE_REGIONS} component={ChooseRegionsPage} />
        <Route
          path={authPaths.DIAGNOSTIC_DASHBOARD}
          component={DiagnosticDashboardPage}
        />
      </Switch>
      {hasUser ? <CrispChat /> : null}
    </DefaultLayout>
  );
};

const MAIN_ADMIN_ROUTES = [
  {
    title: "Company Details",
    path: adminPaths.COMPANY_DETAILS,
    component: CompanyDetailsPage,
  },
  {
    title: "Business Units",
    path: adminPaths.BUSINESS_UNITS,
    component: BusinessUnitPage,
  },
  {
    title: "Users",
    path: adminPaths.USERS,
    component: UsersPage,
  },
];

const AdminRoutes = () => (
  <Route path="/admin">
    <Switch>
      <Route path="/admin" exact component={ClientManagementPage} />
      <Route
        path={`${adminPaths.BUSINESS_UNITS}/:businessUnit/:account`}
        component={BusinessUnitDetailsPage}
      />
      <AdminNav routes={MAIN_ADMIN_ROUTES}>
        {MAIN_ADMIN_ROUTES.map(({ path, component: Component }) => {
          return <Route key={path} path={path} exact component={Component} />;
        })}
      </AdminNav>
    </Switch>
  </Route>
);

const DashboardRoutes: React.FC = () => {
  useAnalytics();
  return (
    <DashboardLayout>
      <Switch>
        <Route path="/" exact component={DashboardPage} />
        <Route path="/opportunities" exact component={OpportunitiesPage} />
        <Route path="/opportunities/:id" exact component={OpportunityPage} />
        <AdminRoutes />

        <Redirect to="/" />
      </Switch>
    </DashboardLayout>
  );
};

const AuthRoutes = ({ hasUser }: AuthRoutesProps) => {
  const location = useLocation();
  const [isAthena, setIsAthena] = useLocalStorageState<boolean>(
    "v_athena",
    true
  );
  const query = {
    ...queryString.parse(location.search),
  };
  const athena = query.athena === "true";

  if (query.athena) {
    if (athena && !isAthena) {
      setIsAthena(true);
    }
    if (!athena && isAthena) {
      setIsAthena(false);
    }
  }

  return !isAthena ? (
    <CORoutes hasUser={!!hasUser} />
  ) : (
    <AthenaRoutes hasUser={!!hasUser} />
  );
};

const Router: React.FC = () => {
  const { user, isLoading } = useAuth();
  const hasAccessToDashboard =
    user?.company?.setupStage === SetupStage.Complete;

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <BrowserRouter>
      {hasAccessToDashboard ? (
        <DashboardRoutes />
      ) : (
        <AuthRoutes hasUser={!!user} />
      )}
    </BrowserRouter>
  );
};
export default Router;
