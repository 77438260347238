/** @jsxImportSource theme-ui */
import { Box, Grid, Typography } from "@material-ui/core";
import diagnosticBg from "assets/diagnostic-bg.jpg";
import AthenaChart from "components/athena/AthenaChart";

import { format } from "date-fns";
import { useAthenaResultsQuery } from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import numbro from "numbro";
import * as React from "react";

interface ChartData {
  current?: number;
  optimum?: number;
  min_cost?: number;
  max_savings?: number;
  values?: Array<Array<number>>;
}

const transformData = (data: any) => {
  return (JSON.parse(data) as unknown) as ChartData;
};
const DiagnosticsAthenaPage: React.FC = () => {
  const { user } = useAuth();
  const formattedEndDate = format(new Date(), "MM/dd/yyyy");
  const formattedEndTime = format(new Date(), "hh:mm aaaa");
  const { data } = useAthenaResultsQuery(
    {},
    { select: (payload) => transformData(payload.AthenaResults?.results) }
  );

  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, rgba(26, 179, 198, 0.9) 3.49%, #004F9E 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 100%), url(${diagnosticBg})`,
        backgroundSize: "contain",
        height: "100vh",
        color: "white",
      }}
    >
      <Grid container justify="center" sx={{ mt: 6 }}>
        <Grid sm={4} item sx={{}}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 300,
            }}
          >
            Hi {user?.name}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              maxWidth: 390,
            }}
          >
            Your Results Summary
          </Typography>
          <Typography
            sx={{
              fontSize: [16, 10],
              lineHeight: ["20px", "16px"],
              maxWidth: [, 210],
            }}
          >
            Data as of {formattedEndDate} at {formattedEndTime}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ height: "100%" }} container>
          <Typography variant="h4">A potential savings of </Typography>
          <Typography variant="h3" sx={{ color: "red", px: 3 }}>
            {numbro(((data && data.max_savings) || 0) * 12 * 3).formatCurrency({
              mantissa: 2,
              thousandSeparated: true,
            })}
          </Typography>
          <Typography variant="h4">(over 3 years)</Typography>
          <Typography variant="h4" sx={{ mb: 4 }}>
            can be realized immediately!!!
          </Typography>

          <AthenaChart chartData={data} />
        </Grid>
      </Grid>
    </Box>
  );
};
DiagnosticsAthenaPage.displayName = "DiagnosticsAthenaPage";
export default DiagnosticsAthenaPage;
