/** @jsxImportSource theme-ui */
import * as React from "react";
import {
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

interface ChartProps {
  chartData?: any;
}
interface ChartData {
  current?: number;
  optimum?: number;
  min_cost?: number;
  max_savings?: number;
  values?: Array<Array<number>>;
}
const AthenaChart: React.FC<ChartProps> = ({ chartData }) => {
  let values;
  if (chartData) {
    values = chartData.values?.map((value: Array<number>) => ({
      x: value[0],
      y: value[1],
    }));
  }
  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        width={500}
        height={300}
        data={values}
        margin={{ top: 20, left: 20, right: 20, bottom: 20 }}
      >
        <XAxis dataKey="x" domain={["auto", "auto"]}>
          <Label stroke="#cccccc" position="bottom" offset={0}>
            Hourly Savings Plan Spend
          </Label>
        </XAxis>
        <YAxis domain={["auto", "auto"]}>
          <Label stroke="#cccccc" angle={270} position="left" offset={15}>
            Overall Spend in Account
          </Label>
        </YAxis>

        <Line dataKey="y" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
};
AthenaChart.displayName = "AthenaChart";
export default React.memo(AthenaChart);
