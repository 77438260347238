/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsxImportSource theme-ui */
import { Typography } from "@material-ui/core";
import * as React from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

interface TargetBoxProps {
  onDrop: (text: string) => void;
}

const DropBox: React.FC<TargetBoxProps> = ({ onDrop }) => {
  const [boxText, setBoxText] = React.useState<string>("");
  const handlePaste = (event: {
    preventDefault: () => void;
    clipboardData: { getData: (arg0: string) => any };
  }) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text");
    setBoxText(text);
    onDrop(text);
  };
  const handleFileDrop = React.useCallback(
    async (item) => {
      if (item) {
        const { files } = item;
        const text = await files[0].text();
        setBoxText(text);
        onDrop(text);
      }
    },
    [onDrop]
  );
  const DropBoxText = () => {
    return (
      <Typography variant="h5" sx={{ color: "#90969C" }}>
        {boxText}
      </Typography>
    );
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        handleFileDrop(item);
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop]
  );
  const isActive = canDrop && isOver;

  return (
    <div
      ref={drop}
      onPaste={handlePaste}
      sx={{
        backgroundColor: isActive ? "#D1D1D2" : "#E9E9EA",
        height: "200px",
        maxWidth: "576px",
        overflow: "auto",
        px: 3,
        py: 3,
      }}
    >
      <DropBoxText />
    </div>
  );
};
DropBox.displayName = "DropBox";
export default DropBox;
