import { authPaths } from "components/router/routes";
import { UserFFragment } from "generated/graphql";

export const getNextOnboardingAthenaPath = (
  currentPathName: string,
  user: UserFFragment | null
) => {
  if (!user) {
    if (
      currentPathName === authPaths.SIGNIN ||
      currentPathName === authPaths.SIGNUP ||
      currentPathName === authPaths.VERIFY_CODE
    ) {
      return null;
    }
    return authPaths.SIGNIN;
  }
  if (user.company?.setupStage) {
    if (currentPathName === authPaths.RESULTS_READY) {
      return null;
    }
    if (currentPathName === authPaths.AWS_RESULTS_PROCESSING) {
      return null;
    }

    if (
      !currentPathName.startsWith(authPaths.CONNECT) &&
      !currentPathName.startsWith(authPaths.VERIFY_SETUP)
    ) {
      return authPaths.CONNECT;
    }
    return null;
  }
  return null;
};
