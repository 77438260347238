/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import ErrorBox from "components/signinup/ErrorBox";
import React from "react";
import { RegisterOptions, useForm } from "react-hook-form";

interface Props {
  onSubmit: (input: InputValues) => Promise<void>;
  data: any;
  isLoading: boolean;
}
interface InputField {
  defaultValue: string;
  props: {
    id: keyof InputValues;
    label: string;
    type?: string;
    validateAgainst?: keyof InputValues;
    validateAgainstMessage?: string;
    minLength?: number;
    pattern?: {
      value: RegExp;
      message: (label: string) => string;
    };
  };
}
export interface InputValues {
  dbName: string;
  tableName: string;
}
const ConnectAthenaForm: React.FC<Props> = ({ onSubmit, isLoading }) => {
  const fields: Array<InputField> = [
    {
      defaultValue: ``,
      props: {
        label: "Enter Athena Database Name",
        id: "dbName",
      },
    },
    {
      defaultValue: ``,
      props: {
        label: "Enter Cost and Usage Table Name",
        id: "tableName",
      },
    },
  ];
  const { register, handleSubmit, errors, formState } = useForm<InputValues>({
    mode: `onBlur`,
    defaultValues: fields.reduce(
      (acc, field) => ({ ...acc, [field.props.id]: field.defaultValue }),
      {}
    ),
  });
  const onSubmitHandler = handleSubmit(async (values) => {
    await onSubmit(values);
  });
  const disabled = !!isLoading || !formState.isValid;
  return (
    <>
      <Grid item xs={12} sm={8} md={6} sx={{ my: 5, px: 4 }}>
        <Typography
          variant="h5"
          sx={{
            mt: 2,
          }}
        >
          In order to find potential saving opportunities we require access to
          your Cost and Usage data. The simplest way to get started is to have
          you run an Athena query in you AWS payer account and share with us the
          resultant csv file.
        </Typography>
        <form noValidate onSubmit={onSubmitHandler}>
          <Box sx={{ marginTop: 3 }}>
            {fields.map((field) => {
              const { id, label, type } = field.props;
              const error = errors[id];
              const registerOptions: RegisterOptions = {
                required: `You must provide a ${label.split("Enter")[1]}`,
              };
              return (
                <Grid
                  item
                  xs={12}
                  direction="row"
                  container
                  key={field.props.id}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={8}>
                    <TextField
                      inputRef={register(registerOptions)}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id={id}
                      label={label}
                      name={id}
                      type={type}
                      sx={{ color: "#90969", mb: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {error && (
                      <Box sx={{ py: -2 }}>
                        <ErrorBox>{error.message}</ErrorBox>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
          <Typography sx={{ mt: 2 }}>
            Need help finding these names?
            <Link component="button" variant="body1" sx={{ ml: 2 }}>
              Click here for help
            </Link>
          </Typography>
          <Grid container justify="flex-end" alignContent="flex-end">
            <Grid item xs={4} sx={{ mt: 4 }}>
              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                size="medium"
                color="primary"
                disableElevation
                fullWidth
                sx={{
                  mt: 2,
                  width: "100%",
                }}
              >
                {isLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Generate Query"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};
export default ConnectAthenaForm;
