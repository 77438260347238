/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Link, Typography, Box } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";

import * as React from "react";

interface Props {
  className?: string;
  link: {
    href: string;
    text: string;
  };
  text: string;
}
const VerifyGCPInfo: React.FC<Props> = ({ className, link, text }) => {
  return (
    <Box className={className}>
      <Link href={link.href} target="_blank" rel="noopener noreferrer">
        <Typography
          variant="subtitle2"
          color="primary"
          sx={{
            fontWeight: 600,
            justify: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          {link.text}
          <LaunchIcon color="action" fontSize="small" sx={{ ml: 1 }} />
        </Typography>
      </Link>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};
VerifyGCPInfo.displayName = "VerifyGCPInfo";
export default VerifyGCPInfo;
