/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import Hero from "components/onboarding/Hero";
import { useSetupRoleMutation } from "generated/graphql";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropBox from "components/verify-setup/VerifyGCPDnD";
import OnboardingBar from "components/navigation/OnboardingBar";
import VerifyGCPModal from "components/verify-setup/VerifyGCPModal";
import { useAuth } from "hooks/use-auth";
import useRouter from "hooks/use-router";
import { authPaths } from "components/router/routes";
import { Link as RouterLink } from "react-router-dom";
import VerifyGCPInfo from "components/verify-setup/VerifyGCPInfo";

const VerifyGCP: React.FC = () => {
  const { push } = useRouter();
  const { profileQuery } = useAuth();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [projectName, setProjectName] = React.useState<string>("");
  const [dataSet, setDataSet] = React.useState<string>("");
  const [droppedText, setDroppedText] = React.useState<string>("");
  const setupRole = useSetupRoleMutation({
    onSuccess: () => {
      profileQuery?.refetch();
    },
  });
  const isLoading = setupRole.isLoading || setupRole.isSuccess;
  const disabled = isLoading || !droppedText;
  const handleSubmit = async () => {
    try {
      await setupRole?.mutateAsync({
        credentials: {
          file: droppedText,
          projectName,
          dataSet,
        },
      });
      push(`CHOOSE_REGIONS`);
    } catch (e) {
      setIsModalOpen(true);
    }
  };
  const infoItems = [
    {
      link: {
        href:
          "https://cloud.google.com/iam/docs/creating-managing-service-account-keys",
        text: "Where do I find my Google Account Service Key?",
      },
      text:
        "In order to run the Virtasant diagnostic against your GCP accounts a service account with permissions will be required.",
    },
    {
      link: {
        href:
          "https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#daily-cost-detail",
        text: "Where do I find project and dataset names?",
      },
      text:
        "When you provide Virtasant access to your Billing BigQuery export we will get accurate information on your cloud spend.",
    },
    {
      link: {
        href:
          "https://docs.google.com/document/d/1AiKnZF8iGday-ZvNhXOEkrD4a_J0mA3GgRPNgRG7UTo/edit#",
        text: "Here is a cli command to create a gcp service account",
      },
      text:
        "The CLI uses a Virtasant created jinja template to create a service account that has all the necessary roles that our platform needs",
    },
  ];
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png);`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
          px: [30, 0],
        }}
      />
      <Grid container justify="center" sx={{ minHeight: "587px" }}>
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            item
            sm={4}
            md={6}
            lg={6}
            container
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ mt: 4 }}>
              <Hero title="Verify your GCP setup.">
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: 2,
                    fontWeight: 600,
                    color: "#EF7B2E",
                    fontSize: "18px",
                  }}
                >
                  Requirments and Tips for this form
                </Typography>
                {infoItems.map((item, index) => (
                  <VerifyGCPInfo
                    link={item.link}
                    text={item.text}
                    key={item.link.href}
                    sx={{ mt: index > 0 ? 4 : 0 }}
                  />
                ))}
              </Hero>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8} md={6} sx={{ my: 5, px: 4 }}>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
                mb: 2,
                color: "#F26D2F",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              Billing Information (Optional)
            </Typography>
            <Typography variant="body1" sx={{ fontStyle: "italic", mb: 2 }}>
              Enter your Billing BigQuery information below.
            </Typography>
            <TextField
              size="small"
              fullWidth
              id="project-name"
              label="Cost Project Name (optional)"
              variant="outlined"
              sx={{ mb: 3 }}
              onChange={(val) => setProjectName(val.target.value)}
            />
            <TextField
              size="small"
              fullWidth
              id="dataset-name"
              label="Cost DataSet Name (optional)"
              variant="outlined"
              sx={{ mb: 3 }}
              onChange={(val) => setDataSet(val.target.value)}
            />
            <Typography
              variant="h5"
              sx={{
                mt: 2,
                mb: 2,
                color: "#F26D2F",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              Google Accoung Service Definition (Required)
            </Typography>
            <Typography variant="body1" sx={{ fontStyle: "italic", mb: 2 }}>
              ‘Drag and Drop’ or ‘Copy and Paste’ your JSON file below.
            </Typography>
            <DndProvider backend={HTML5Backend}>
              <DropBox onDrop={setDroppedText} />
            </DndProvider>
          </Grid>
          <Grid
            container
            sx={{ mb: 4, mx: 4 }}
            justify="space-between"
            alignContent="flex-end"
          >
            <Grid item xs={2}>
              <Button
                to={`${authPaths.CONNECT}/GCP`}
                component={RouterLink}
                variant="text"
                color="primary"
                fullWidth
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={disabled}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <VerifyGCPModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </Box>
  );
};
VerifyGCP.displayName = "VerifyGCP";
export default VerifyGCP;
