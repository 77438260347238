/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React from "react";
import Hero from "components/onboarding/Hero";
import ConnectAthenaForm, {
  InputValues,
} from "components/athena/ConnectAthenaForm";
import ConnectAthenaSQL from "components/athena/ConnectAthenaSQL";
import ShareAthenaResults from "components/athena/ShareAthenaResults";
import { ToSDialog } from "components/general/ToS";

interface SwitcherProps {
  pageSwitcher: string;
  setPage: (f: string) => void;
  sql: string;
  handleFileSelect: (s: string) => void;
  handleSubmit: (i: InputValues) => Promise<void>;
  handleUpload: () => void;
  isLoading: boolean;
  isCsvLoading: boolean;
}

const HeroSubtitle: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <>
      <Typography sx={{ mt: 4 }}>
        Please review
        <Link
          component="button"
          variant="body1"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            setOpen(true);
          }}
          sx={{ ml: 2 }}
        >
          Terms of Service.
        </Link>
      </Typography>
      <ToSDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
const Switcher: React.FC<SwitcherProps> = ({
  pageSwitcher,
  setPage,
  sql,
  handleFileSelect,
  handleSubmit,
  handleUpload,
  isCsvLoading,
  isLoading,
}) => {
  switch (pageSwitcher) {
    case "SHARE_RESULTS":
      return (
        <>
          <Grid
            xs={12}
            item
            container
            justify="center"
            alignItems="flex-end"
            sx={{ px: 4 }}
            direction="row"
          >
            <Grid
              item
              container
              direction="column"
              justify="space-between"
              xs={6}
            >
              <Grid item sx={{ px: 3 }}>
                <Hero title="Share results of your query" />
              </Grid>
            </Grid>
            <Grid xs={6} item>
              <ShareAthenaResults
                onFileSelected={handleFileSelect}
                isLoading={isCsvLoading}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignContent="flex-end"
            justify="space-between"
            sx={{ pb: 4, px: 4 }}
          >
            <Grid item xs={2}>
              <Button
                onClick={() => setPage("GET_QUERY")}
                variant="text"
                size="medium"
                color="primary"
                disableElevation
                fullWidth
                sx={{
                  textAlign: "left",
                  mt: 2,
                  width: "100%",
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} container direction="row" justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpload()}
              >
                {isCsvLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Upload to Virtasant"
                )}
              </Button>
            </Grid>
          </Grid>
        </>
      );
    case "GET_QUERY":
      return (
        <>
          <Grid
            container
            item
            sm={4}
            md={6}
            lg={6}
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ my: "auto" }}>
              <Hero title="Generate your Athena Query">
                <HeroSubtitle />
              </Hero>
              <Typography variant="body1" sx={{ mt: 4, fontWeight: 400 }}>
                We assume that you already have Cost and Usage reports being
                delivered to an S3 bucket in your AWS payer account on an hourly
                basis. If this is not the case you can click on the link below
                to quickly and easily help get you set up with this capability.
              </Typography>
              <Link component="button" variant="body1" sx={{ mt: 2 }}>
                Set up Automated Cost and Usage reporting
              </Link>
              <Grid item xs={4} sx={{ mt: 5 }}>
                <Button
                  onClick={() => setPage("GEN_QUERY")}
                  variant="text"
                  size="medium"
                  color="primary"
                  disableElevation
                  fullWidth
                  sx={{
                    mt: 2,
                    width: "100%",
                  }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <ConnectAthenaSQL
            sql={sql}
            onSubmit={() => setPage("SHARE_RESULTS")}
          />
        </>
      );
    default:
      return (
        <>
          <Grid
            sm={4}
            md={6}
            lg={6}
            item
            container
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ my: "auto" }}>
              <Hero title="Generate your Athena Query">
                <HeroSubtitle />
              </Hero>
              <Typography variant="body1" sx={{ mt: 4, fontWeight: 400 }}>
                We assume that you already have Cost and Usage reports being
                delivered to an S3 bucket in your AWS payer account on an hourly
                basis. If this is not the case you can click on the link below
                to quickly and easily help get you set up with this capability.
              </Typography>
              <Link component="button" variant="body1" sx={{ mt: 2 }}>
                Set up Automated Cost and Usage reporting
              </Link>
            </Grid>
          </Grid>
          <ConnectAthenaForm
            data={{ sql }}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </>
      );
  }
};
export default React.memo(Switcher);
