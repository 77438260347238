/** @jsxImportSource theme-ui */
import { Button, Grid } from "@material-ui/core";
import * as React from "react";
import useRequireAuth from "hooks/use-require-auth";
import Hero from "components/onboarding/Hero";
import PlatformRadioGroup from "components/platform/PlatformRadioGroup";
import awsImage from "assets/platforms/aws.png";
import gcpImage from "assets/platforms/gcp.png";
import ConnectContainer from "components/connect/ConnectContainer";
import { Link as RouterLink } from "react-router-dom";
import { Cloud } from "../generated/graphql";
import { authPaths } from "../components/router/routes";

const ConnectPage: React.FC = () => {
  const { user } = useRequireAuth();
  const firstCompanyCloud = user?.company?.cloudAccounts?.edges[0]?.node?.cloud;
  const [platform, setPlatform] = React.useState<Cloud | null>(
    firstCompanyCloud || null
  );
  const isNextButtonDisabled = !platform;
  const platforms = [
    {
      name: "Amazon Web Services",
      value: Cloud.Aws,
      img: awsImage,
    },
    {
      name: "Google Cloud Platform",
      value: Cloud.Gcp,
      img: gcpImage,
    },
  ];

  return (
    <ConnectContainer>
      <Grid
        container
        sx={{
          bg: "white",
          borderRadius: 10,
          minHeight: [, 515],
          maxWidth: ["100%", 1240],
          mx: "auto",
          pt: [20, 0],
        }}
      >
        <Grid item sm={4} md={6} container justify="center" alignItems="center">
          <Grid item md={8}>
            <Hero title="Please choose a cloud service to diagnose." />
          </Grid>
        </Grid>
        <Grid
          item
          sm={8}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            pl: 3,
            pr: 3,
          }}
        >
          <PlatformRadioGroup
            selected={platform}
            setSelected={setPlatform}
            items={platforms}
          />
          <Grid container justify="flex-end" sx={{ pr: 4, pb: 4 }}>
            <Grid item xs={3}>
              <Button
                disabled={isNextButtonDisabled}
                component={RouterLink}
                to={`${authPaths.CONNECT}/${platform}`}
                size="medium"
                variant="contained"
                color="primary"
                fullWidth
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
ConnectPage.displayName = "ConnectPage";
export default ConnectPage;
