/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Box, Button, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import Hero from "components/onboarding/Hero";
import { ToSDialog } from "components/general/ToS";
import { useAuth } from "hooks/use-auth";
import useRouter from "hooks/use-router";
import { Link as RouterLink } from "react-router-dom";
import { Cloud, useSetupScriptMutation } from "generated/graphql";
import { authPaths } from "components/router/routes";
import ConnectContainer from "components/connect/ConnectContainer";

const ConnectGcpPage: React.FC = () => {
  const { push } = useRouter();
  const permissionsLeft = [
    "Service Usage Consumer",
    "BigQuery Data Owner",
    "BigQuery Job User",
    "Monitoring Viewer",
    "Log Viewer",
    "Private Log Viewer",
    "Compute Viewer",
    "Cloud SQL Viewer",
    "Spanner Viewer",
  ];
  const permissionsRight = [
    "Storage Object viewer",
    "Storage Transfer Viewer",
    "Cloud Memorystore Redis Viewer",
    "Cloud Memorystore Memcached Viewer",
    "Firebase Viewer",
    "GKE Hub Viewer",
    "Kubernetes Engine Cluster viewer",
    "DNS Reader",
    "Cloud KMS CryptoKey Public Key Viewer",
  ];
  const { profileQuery } = useAuth();
  const setupScript = useSetupScriptMutation();
  const handleSubmit = async () => {
    await setupScript?.mutateAsync({
      cloud: Cloud.Gcp,
    });
    await profileQuery?.refetch();
    push(`${authPaths.VERIFY_SETUP}/${Cloud.Gcp}`);
  };

  const HeroSubtitle: React.FC = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    return (
      <>
        <Typography sx={{ mt: 4 }}>
          Please review
          <Link
            component="button"
            variant="body1"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              setOpen(true);
            }}
            sx={{ ml: 2 }}
          >
            Terms of Service.
          </Link>
        </Typography>
        <ToSDialog open={open} onClose={() => setOpen(false)} />
      </>
    );
  };
  return (
    <ConnectContainer>
      <Grid
        container
        justify="center"
        sx={{ height: "100%", minHeight: "587px" }}
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            item
            sm={4}
            md={6}
            lg={6}
            container
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={10} sx={{ my: "auto" }}>
              <Hero title="Grant us permission to access GCP">
                <Typography variant="h5" sx={{ mt: 2, color: "#636C74" }}>
                  Please create a service account with the following role and
                  permissions.
                </Typography>
                <HeroSubtitle />
              </Hero>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={6} sx={{ my: 5, px: 4 }}>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
                color: "#F26D2F",
                fontWeight: 600,
              }}
            >
              Step 1.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: 1,
                color: "black",
                fontWeight: 500,
              }}
            >
              Create Service Account User
            </Typography>
            <Box>
              <Typography
                variant="body1"
                color="primary"
                sx={{
                  mt: 1,
                }}
              >
                Read create and managing service accounts
              </Typography>
            </Box>
            <Typography
              variant="h5"
              sx={{
                mt: 4,
                color: "#F26D2F",
                fontWeight: 600,
              }}
            >
              Step 2.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: 1,
                color: "black",
                fontWeight: 500,
              }}
            >
              Allow these permissions
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                {permissionsLeft.map((permission) => (
                  <Typography variant="body1" key={permission}>
                    {permission}
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={6}>
                {permissionsRight.map((permission) => (
                  <Typography variant="body1" key={permission}>
                    {permission}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ mb: 4, mx: 4 }}
            justify="space-between"
            alignContent="flex-end"
          >
            <Grid item xs={2}>
              <Button
                to={authPaths.CONNECT}
                component={RouterLink}
                variant="text"
                color="primary"
                fullWidth
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disableElevation
                fullWidth
                onClick={handleSubmit}
                sx={{
                  mt: 2,
                  width: "100%",
                }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
ConnectGcpPage.displayName = "Connect Gcp";
export default ConnectGcpPage;
